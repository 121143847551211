<template>
  <div>
    <siteHeader />
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <h3>Member</h3>
              <hr class="my-4" />
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      label="First Name"
                      placeholder="First Name"
                      :value="model.name"
                      @input="(event) => (model.name = event.target.value)"
                    />

                    <div class="error mb-2" v-if="v$.model.name.$error">
                      First Name is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      label="Last Name"
                      placeholder="Last Name"
                      :value="model.lastname"
                      @input="(event) => (model.lastname = event.target.value)"
                    />

                    <div class="error mb-2" v-if="v$.model.lastname.$error">
                      Last Name is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="Member Group">
                      <el-select
                        style="width: 100%"
                        v-model="model.member_group"
                        placeholder="Member Group"
                      >
                        <el-option
                          v-for="option in memberData"
                          :value="option.id"
                          :label="option.members_group_name"
                          :key="option.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>

                    <div class="error mb-2" v-if="v$.model.member_group.$error">
                      Member Group is required.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="Address">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        :value="model.address"
                        @input="(event) => (model.address = event.target.value)"
                        placeholder="Address"
                      ></textarea>
                    </base-input>
                    <div class="error mb-2" v-if="v$.model.address.$error">
                      Address is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="Membership">
                      <el-select
                        style="width: 100%"
                        v-model="model.membership"
                        placeholder="Membership"
                      >
                        <el-option
                          v-for="option in membershipData"
                          :value="option.id"
                          :label="option.membership_name"
                          :key="option.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <!-- <div class="error mb-2" v-if="v$.model.membership.$error">
                      Membership is required.
                    </div> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      label="Mobile"
                      placeholder="Mobile"
                      input-classes="form-control-alternative"
                      :value="model.phone"
                      @input="(event) => (model.phone = event.target.value)"
                    />
                    <div class="error mb-2" v-if="v$.model.phone.$error">
                      Mobile is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="Subscriptions">
                      <el-select
                        style="width: 100%"
                        multiple
                        v-model="model.subscriptions"
                        placeholder="Subscriptions"
                      >
                        <el-option
                          v-for="option in subscriptionData"
                          :value="option.id"
                          :label="option.subscription_name"
                          :key="option.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                    <!-- <div
                      class="error mb-2"
                      v-if="v$.model.subscriptions.$error"
                    >
                      Subscriptions is required.
                    </div> -->
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="Subscription Charge Type">
                      <el-select
                        style="width: 100%"
                        v-model="model.subscription_Charge_type"
                        placeholder="Subscription Charge Type"
                      >
                        <el-option
                          v-for="option in subscriptionChargeType"
                          :value="option.value"
                          :label="option.label"
                          :key="option.label"
                        >
                        </el-option>
                      </el-select>
                    </base-input>

                    <div
                      class="error mb-2"
                      v-if="v$.model.subscription_Charge_type.$error"
                    >
                      Subscription Charge Type is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      label="Email"
                      placeholder="Email"
                      input-classes="form-control-alternative"
                      :value="model.email"
                      @input="(event) => (model.email = event.target.value)"
                    />
                    <div class="error mb-2" v-if="v$.model.email.$error">
                      Invalid Email.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="Note">
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        v-model="model.note"
                        placeholder="Note"
                      ></textarea>
                    </base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input label="Joining Date">
                      <flat-picker
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ altFormat: 'd/m/Y', altInput: true }"
                        class="form-control datepicker"
                        v-model="model.joiningdate"
                      >
                      </flat-picker>
                    </base-input>
                    <div class="error mb-2" v-if="v$.model.joiningdate.$error">
                      Joining Date is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input label="Cease Date">
                      <flat-picker
                        @on-open="focus"
                        @on-close="blur"
                        :config="{ altFormat: 'd/m/Y', altInput: true }"
                        class="form-control datepicker"
                        v-model="model.ceasedate"
                      >
                      </flat-picker>
                    </base-input>
                    <!-- <div class="error mb-2" v-if="v$.model.ceasedate.$error">
                      Field is required.
                    </div> -->
                  </div>
                </div>
                <div class="row1">
                  <div class="custom-control custom-checkbox mb-3">
                    <input
                      class="custom-control-input"
                      id="customCheck1"
                      type="checkbox"
                      v-model="model.is_active"
                      false-value="2"
                      true-value="1"
                    />
                    <label class="custom-control-label" for="customCheck1"
                      >Active</label
                    >
                  </div>
                </div>
              </div>
              <div class="row" v-if="id.data == 'undefined'">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="create()"
                  >
                    Create
                  </button>
                </div>
              </div>
              <div class="row" v-if="id.data != 'undefined'">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="Update()"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
            <br />
            <div v-if="isLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Created Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
            <div v-if="isUpdateLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Updated Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric } from "@vuelidate/validators";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
const memberRepository = RepositoryFactory.get("member");
const membergroupRepository = RepositoryFactory.get("membergroup");
const memberShipRepository = RepositoryFactory.get("membership");
const subscriptionRepository = RepositoryFactory.get("subscription");

import { ElSelect, ElOption } from "element-plus";
export default {
  components: {
    flatPicker,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  name: "add_member",

  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      isUpdateLoading: false,
      memberData: [],
      tableData: [],
      membershipData: [],
      subscriptionData: [],
      model: {
        subscription_Charge_type: "Subscrption Charge Type1",
      },
      subscriptionChargeType: [
        {
          value: "Subscrption Charge Type1",
          label: "Subscrption Charge Type1",
        },
        {
          value: "Subscrption Charge Type2",
          label: "Subscrption Charge Type2",
        },
      ],
    };
  },

  validations() {
    return {
      model: {
        name: { required },
        member_group: { required },
        address: { required },
        // membership: { required },
        phone: { required, numeric },
        lastname: { required },
        subscription_Charge_type: { required },
        email: { required, email },
        // ceasedate: { required },
        joiningdate: { required },
      },
    };
  },

  methods: {
    // Pushes posts to the server when called.
    stripslashes(str) {
      return (str + "").replace(/\\(.?)/g, function (s, n1) {
        switch (n1) {
          case "\\":
            return "\\";
          case "0":
            return "\u0000";
          case "":
            return "";
          default:
            return n1;
        }
      });
    },
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }

      if (this.model.is_active != 1) {
        this.model.is_active = "2";
      }

      let subscriptions = JSON.stringify(this.model.subscriptions);
      if (subscriptions) {
        this.model.subscriptions = subscriptions;
      }
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await memberRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/member/viewmember");
        }, 1000);
      }
    },
    async Update() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      delete this.model.edit;
      let subscriptions = JSON.stringify(this.model.subscriptions);
      if (subscriptions) {
        this.model.subscriptions = subscriptions;
      }
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await memberRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/member/viewmember");
        }, 1000);
      }
    },

    async getMemberGroupList() {
      this.tableData = await membergroupRepository.getMember();
      this.memberData = this.tableData.data;
    },
    async getMembershipList() {
      this.tableData = await memberShipRepository.getMembership();
      this.membershipData = this.tableData.data;
    },
    async getSubscriptionList() {
      this.tableData = await subscriptionRepository.getSubscription();
      this.subscriptionData = this.tableData.data;
    },
  },
  async mounted() {
    await this.getMemberGroupList();
    await this.getMembershipList();
    await this.getSubscriptionList();

    this.id = this.$route.params;
    let data = await memberRepository.getMemberByid(this.id.data);
    if (data.data.length != 0) {
      this.model = data.data[0];
      this.model.subscriptions = this.stripslashes(data.data[0].subscriptions);
      this.model.subscriptions = JSON.parse(this.model.subscriptions);
    }
  },
};
</script>
<style></style>
